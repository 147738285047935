import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SharedModule } from '@app/shared/shared.module';

import { AuthComponent } from './auth.component';
import { LoginComponent } from './login/login.component';
import { AuthRoutingModule } from './auth-routing.module';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { SelectSiloComponent } from './select-silo/select-silo.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';

@NgModule({
    declarations: [AuthComponent, LoginComponent, SelectSiloComponent, ResetPasswordComponent, ChangePasswordComponent],
    imports: [CommonModule, SharedModule, AuthRoutingModule],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AuthModule {}
