import { Component, inject, OnInit } from '@angular/core';
import { AuthApiService } from '@app/data/services/auth-api.service';
import { ActivatedRoute, Router } from '@angular/router';
import { catchError, finalize, switchMap, tap } from 'rxjs/operators';
import { BehaviorSubject, EMPTY, of } from 'rxjs';
import { AppStateService } from '@app/core/services/app-state.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { untilDestroyed } from '@app/core/helpers/until-destroyed';
import { LoginResponse } from '@app/data/models/auth.model';
import { ValidationService } from '@app/core/services';

const sessionExpired = 'session-expired';
@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['../auth.component.scss'],
    standalone: false,
})
export class LoginComponent implements OnInit {
    errorMsg = new BehaviorSubject<string>('');
    isQueryParamsErrorMsg = false;
    infoMsg = '';
    isSessionExpired = false;
    form: FormGroup;
    pendingLoginRequest = false;
    private hidingMessageDelayed = true;
    private untilDestroy = untilDestroyed();
    #validationService = inject(ValidationService);

    constructor(
        private authService: AuthApiService,
        private route: ActivatedRoute,
        private router: Router,
        private appStateService: AppStateService,
        private formBuilder: FormBuilder,
    ) {}

    ngOnInit() {
        this.initForm();

        this.route.queryParams
            .pipe(
                tap((params) => {
                    const flag = params.flag;
                    const email = params.email || '';

                    this.infoMsg = params.message ? atob(params.message) : '';
                    this.emailControl.setValue(email);
                    this.isSessionExpired = flag === sessionExpired;
                    this.isQueryParamsErrorMsg = !!params.errorMessage;
                    if (this.isQueryParamsErrorMsg) this.errorMsg.next(params.errorMessage);

                    setTimeout(() => {
                        this.hidingMessageDelayed = false;
                    }, 1000);
                }),
            )
            .subscribe();
    }

    initForm() {
        this.form = this.formBuilder.group({
            email: ['', [Validators.required, Validators.email]],
            password: ['', [Validators.required]],
        });

        setTimeout(() => {
            this.form.valueChanges
                .pipe(
                    this.untilDestroy(),
                    tap(() => {
                        this.clearError();
                    }),
                )
                .subscribe();
        }, 1000);
    }

    login() {
        this.pendingLoginRequest = true;
        this.authService
            .login(this.form.value)
            .pipe(
                switchMap((response: LoginResponse) => {
                    if (response['2fa_enabled']) {
                        // Redirect to OTP check with temp_token as a query parameter
                        this.router.navigate(['otp-check'], { queryParams: { temp_token: response.temp_token } });
                        return EMPTY; // Stop the observable chain
                    } else {
                        // Proceed with normal login flow
                        return this.authService.getSilos();
                    }
                }),
                tap((silos) => {
                    if (silos.length > 1) {
                        this.router.navigate(['select-silo']);
                    } else {
                        const oneSilo = silos[0];
                        if (oneSilo) {
                            this.appStateService.setUserSilo(oneSilo);
                        } else {
                            this.errorMsg.next('You are not assigned to any silo');
                        }
                    }
                }),
                catchError((err) => {
                    const errorMsg = this.#validationService.handleServerErrors(err, this.form);
                    this.errorMsg.next(errorMsg);
                    return of(err);
                }),
                finalize(() => {
                    this.pendingLoginRequest = false;
                }),
            )
            .subscribe();
    }

    forgottenPassword() {
        this.router.navigate(['reset-password'], {
            queryParams: this.emailControl.value
                ? {
                      email: this.emailControl.value,
                  }
                : {},
            queryParamsHandling: 'merge',
        });
    }

    clearError() {
        if (!this.isQueryParamsErrorMsg) this.errorMsg.next('');
        if (!this.hidingMessageDelayed) this.infoMsg = '';
    }

    get emailControl() {
        return this.form.get('email');
    }

    get passwordControl() {
        return this.form.get('password');
    }

    get submitButtonDisabled() {
        return !this.emailControl.valid || !this.passwordControl.valid || this.pendingLoginRequest;
    }
}
