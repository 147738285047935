import { Component, OnInit, inject, ChangeDetectionStrategy, signal, computed } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthApiService } from '@app/data/services/auth-api.service';
import { AppStateService } from '@app/core/services/app-state.service';
import { catchError, finalize, switchMap, tap } from 'rxjs/operators';
import { of } from 'rxjs';
import { SharedModule } from '@app/shared/shared.module';

@Component({
    selector: 'app-otp-check',
    templateUrl: './otp-check.component.html',
    styleUrls: ['../auth.component.scss', './otp-check.component.scss'],
    imports: [SharedModule],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OtpCheckComponent implements OnInit {
    form: FormGroup;
    errorMsg = signal('');
    pendingRequest = signal(false);
    private tempToken = '';
    private formBuilder = inject(FormBuilder);
    private router = inject(Router);
    private route = inject(ActivatedRoute);
    private authService = inject(AuthApiService);
    private appStateService = inject(AppStateService);

    ngOnInit() {
        this.initForm();
        this.route.queryParams.subscribe((params) => {
            this.tempToken = params['temp_token'];
            if (!this.tempToken) {
                this.router.navigate(['/login']);
            }
        });
    }

    initForm() {
        this.form = this.formBuilder.group({
            otp: ['', [Validators.required]],
        });
    }

    submitOtp() {
        if (this.form.valid && this.tempToken) {
            this.pendingRequest.set(true);
            this.authService
                .validateOTP(this.form.value.otp, this.tempToken)
                .pipe(
                    switchMap(() => this.authService.getSilos()),
                    tap((silos) => {
                        if (silos.length > 1) {
                            this.router.navigate(['select-silo']);
                        } else {
                            this.appStateService.setUserSilo(silos[0]);
                        }
                    }),
                    catchError((err) => {
                        this.errorMsg.set('Invalid OTP. Please try again.');
                        return of(null);
                    }),
                    finalize(() => {
                        this.pendingRequest.set(false);
                    }),
                )
                .subscribe();
        }
    }

    get otpControl() {
        return this.form.get('otp');
    }
}
