import { ChangeDetectionStrategy, Component, OnInit, computed, signal } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { AuthApiService } from '@app/data/services/auth-api.service';
import { BehaviorSubject, Observable, combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';
import { Silo } from '@app/data/models';
import { AppStateService } from '@app/core/services/app-state.service';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { untilDestroyed } from '@app/core/helpers/until-destroyed';

@Component({
    selector: 'app-select-silo',
    templateUrl: './select-silo.component.html',
    styleUrls: ['../auth.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false,
})
export class SelectSiloComponent implements OnInit {
    silos = signal<Silo[]>([]);
    errorMsg$: Observable<string>;
    form: FormGroup;
    siloFilterControl = new FormControl('');

    $searchTerm = toSignal(this.siloFilterControl.valueChanges);
    $filteredSilos = computed(() => {
        const term = this.$searchTerm()?.toLowerCase();
        if (!term) {
            return this.silos();
        }
        return this.silos().filter((silo) => silo.name.toLowerCase().includes(term));
    });
    private pendingSelectingSiloRequest$ = new BehaviorSubject(false);
    private untilDestroy = untilDestroyed();

    constructor(
        private authService: AuthApiService,
        private appStateService: AppStateService,
        private formBuilder: FormBuilder,
    ) {}

    ngOnInit() {
        this.initForm();
        this.getSilos();

        this.errorMsg$ = this.appStateService.userSiloSetErrorMessage$;
    }

    initForm() {
        this.form = this.formBuilder.group({
            silo: ['', [Validators.required]],
        });
    }

    applySilo() {
        this.pendingSelectingSiloRequest$.next(true);
        const siloIndex = this.silos().findIndex((s) => s.id === this.siloControl.value);
        this.appStateService.setUserSilo(this.silos()[siloIndex]);
    }

    private getSilos() {
        if (this.authService.silos) {
            this.silos.set(this.authService.silos);
        } else {
            this.authService.getSilos().subscribe((data) => this.silos.set(data));
        }
    }

    get siloControl() {
        return this.form.get('silo');
    }

    get selectSiloBlocked$() {
        return combineLatest([this.errorMsg$, this.pendingSelectingSiloRequest$]).pipe(
            this.untilDestroy(),
            map(([errorMessage, pendingRequest]) => !errorMessage && pendingRequest),
        );
    }

    onSiloSelectOpenedChange(isOpen: boolean) {
        if (!isOpen) {
            this.siloFilterControl.reset('');
        }
    }
}
