<form class="login-form" novalidate [formGroup]="form">
    <div class="info-msg" [ngClass]="{ 'alert alert-danger': errorMsg$ | async }">{{ errorMsg$ | async }}</div>
    <mat-form-field hideRequiredMarker>
        <mat-label>Select silo</mat-label>
        <mat-select
            class="form-control"
            panelClass="silo-select"
            data-testid="silo-select"
            name="selectedSilo"
            formControlName="silo"
            required
            (openedChange)="onSiloSelectOpenedChange($event)"
        >
            @if (silos().length >= 10) {
                <div class="select-search">
                    <input
                        [formControl]="siloFilterControl"
                        placeholder="Search by Silo name"
                        (click)="$event.stopPropagation()"
                    />
                </div>
            }

            @if ($filteredSilos().length === 0) {
                <div class="no-results">No silos found</div>
            } @else {
                @for (silo of $filteredSilos(); track silo) {
                    <mat-option [value]="silo.id">
                        {{ silo.name | truncate }}
                    </mat-option>
                }
            }
        </mat-select>
    </mat-form-field>

    <button
        mat-button
        class="btn submit_btn auth-page-btn"
        data-testid="apply-selected-silo"
        (click)="applySilo()"
        [disabled]="!siloControl.value || (selectSiloBlocked$ | async)"
    >
        <span *msLoadingSpinner="selectSiloBlocked$ | async; diameter: 20">Apply</span>
    </button>
</form>
