<form class="login-form" novalidate [formGroup]="form">
    <div
        id="otp-error-message"
        class="info-msg"
        [ngClass]="{ 'alert alert-danger': errorMsg() }"
        data-testid="alert-error"
    >
        {{ errorMsg() }}
    </div>
    <mat-form-field hideRequiredMarker>
        <mat-label>One-time password</mat-label>
        <mat-icon matIconPrefix><i class="fas fa-shield-alt"></i></mat-icon>
        <input
            class="form-control validate"
            id="otp_input"
            formControlName="otp"
            type="text"
            autocomplete="off"
            matInput
        />
        @if (!otpControl.value || otpControl.invalid) {
            <mat-error>Please enter a valid OTP</mat-error>
        }
    </mat-form-field>
    <button
        class="btn submit_btn auth-page-btn"
        mat-button
        (click)="submitOtp()"
        [disabled]="!form.valid || pendingRequest()"
        data-testid="submit-otp"
    >
        <span *msLoadingSpinner="pendingRequest(); diameter: 20">Verify</span>
    </button>
</form>
