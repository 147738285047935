<div class="auth-section">
    <div class="background-blur-container">
        <div class="auth-container" id="auth-page">
            <div class="form-block">
                @if (titleLogoSrc) {
                    <div class="image-logo">
                        <img [src]="titleLogoSrc" alt="Company logo" />
                    </div>
                }
                @if (title) {
                    <div class="text-logo" [innerHTML]="title"></div>
                }

                <router-outlet></router-outlet>
            </div>
        </div>
    </div>
</div>
