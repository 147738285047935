import { Component, OnInit } from '@angular/core';
import { AuthApiService } from '@app/data/services/auth-api.service';
import { ActivatedRoute, Router } from '@angular/router';
import { of } from 'rxjs';
import { catchError, finalize, tap } from 'rxjs/operators';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
    selector: 'app-reset-password',
    templateUrl: './reset-password.component.html',
    styleUrls: ['../auth.component.scss'],
    standalone: false,
})
export class ResetPasswordComponent implements OnInit {
    errorMsg = '';
    form: FormGroup;
    pendingResetPasswordRequest = false;

    constructor(
        private authService: AuthApiService,
        private route: ActivatedRoute,
        private router: Router,
        private formBuilder: FormBuilder,
    ) {}

    ngOnInit() {
        this.initForm();
        // Get params from url
        this.route.queryParams
            .pipe(
                tap((params) => {
                    this.emailControl.setValue(params.email || '');
                }),
            )
            .subscribe();
    }

    initForm() {
        this.form = this.formBuilder.group({
            email: ['', [Validators.required, Validators.email]],
        });
    }

    requestPasswordReset() {
        this.pendingResetPasswordRequest = true;
        this.authService
            .requestPasswordReset(this.emailControl.value)
            .pipe(
                tap(() => {
                    this.emailWasSent();
                }),
                catchError((err) => {
                    this.errorMsg = err.error.email;
                    return of(err);
                }),
                finalize(() => {
                    this.pendingResetPasswordRequest = false;
                }),
            )
            .subscribe();
    }

    emailWasSent() {
        return this.router.navigate(['login'], {
            queryParams: {
                email: this.emailControl.value,
                message: btoa('A message with instructions has been sent to your email'),
            },
            queryParamsHandling: 'merge',
        });
    }

    goBackToLogin() {
        this.router.navigate(['login']);
    }

    clearError() {
        this.errorMsg = '';
    }

    get emailControl() {
        return this.form.get('email');
    }
}
