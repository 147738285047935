import { Injectable } from '@angular/core';
import { FeatureFlagsService } from '@app/data/services/feature-flags.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, filter, map, switchMap, tap } from 'rxjs/operators';

import * as FeatureFlagsActions from './feature-flags.actions';
import * as fromUserStore from '../user';
import * as fromRouterStore from '../router';
import { getFeatureFlagsSuccess } from './feature-flags.actions';
import { Router } from '@angular/router';
import { Routes } from '@app/core/constants/routes';
import { Store } from '@ngrx/store';
import { AppState } from '../root-store';
import { concatLatestFrom } from '@ngrx/operators';

@Injectable()
export class FeatureFlagsEffects {
    constructor(
        private actions$: Actions,
        private featureFlagsService: FeatureFlagsService,
        private router: Router,
        private store: Store<AppState>,
    ) {}

    getFeatureFlags$ = createEffect(() =>
        this.actions$.pipe(
            ofType(FeatureFlagsActions.getFeatureFlags),
            switchMap(() =>
                this.featureFlagsService.loadFlags().pipe(
                    map((flags: any) => FeatureFlagsActions.getFeatureFlagsSuccess({ featureFlags: flags })),
                    catchError(() => of({ type: '[Feature Flags] Get feature flags error' })),
                ),
            ),
        ),
    );

    getFeatureFlagsSuccess$ = createEffect(() =>
        this.actions$.pipe(
            ofType(getFeatureFlagsSuccess),
            concatLatestFrom(() => this.store.select(fromUserStore.selectSwitchSiloStatus)),
            filter(([, switchSiloStatus]) => switchSiloStatus === fromUserStore.SwitchSiloStatus.UPDATING_SILO_INFO),
            concatLatestFrom(() => this.store.select(fromRouterStore.selectUrl)),
            tap(([, route]) => {
                if (!route.startsWith(Routes.ADMIN)) {
                    this.router.navigate([Routes.SEARCH]);
                } else {
                    this.router.navigate([route]);
                }
            }),
            map(() => fromUserStore.switchSiloCompleted()),
        ),
    );
}
