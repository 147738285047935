<form class="login-form" novalidate [formGroup]="passwordUpdateForm">
    <div class="failed-msg" data-testid="error-message" [ngClass]="{ 'alert alert-danger': errorMsg }">
        {{ errorMsg }}
    </div>

    @if (showForm) {
        <mat-form-field hideRequiredMarker class="new-password-field" subscriptSizing="dynamic">
            <mat-label>New password:</mat-label>
            <mat-icon matPrefix><i class="fal fa-lock"></i></mat-icon>
            <input
                id="new_password"
                type="password"
                class="form-control validate"
                autocomplete="new-password"
                (focus)="clearError()"
                formControlName="newPassword"
                required
                matInput
            />
            @if (newPasswordInvalid) {
                <mat-error data-testid="invalid-password-message"
                    >Password should contain at least 8 characters include lower/upper case and numbers or
                    symbols.</mat-error
                >
            }
        </mat-form-field>
    }
    @if (showForm) {
        <mat-form-field hideRequiredMarker>
            <mat-label>Repeat password:</mat-label>
            <mat-icon matPrefix><i class="fal fa-lock"></i></mat-icon>
            <input
                id="repeat_password"
                type="password"
                class="form-control validate"
                autocomplete="repeat-password"
                (focus)="clearError()"
                formControlName="repeatPassword"
                required
                matInput
            />
            @if (repeatPasswordControl.errors?.mismatch) {
                <mat-error data-testid="invalid-repeat-password-message">Passwords should be the same.</mat-error>
            }
        </mat-form-field>
    }
    @if (showForm) {
        <button
            data-testid="update-password"
            class="btn submit_btn auth-page-btn reset-password-btn"
            (click)="requestPasswordUpdate()"
            mat-button
            [disabled]="submitButtonDisabled"
        >
            <span *msLoadingSpinner="pendingChangePasswordRequest; diameter: 20">Update password</span>
        </button>
    }

    <div class="small-button-wrapper--center additional-options-wrapper" id="back_to_login">
        <a (click)="goBackToLogin()" class="small-button-link">Go back </a>
    </div>
</form>
