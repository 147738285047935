import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { enableProdMode } from '@angular/core';
import * as Sentry from '@sentry/angular';

import { environment } from './environments/environment';
import { AppModule } from './app/';

if (environment.sentry_env && environment.sentry_dsn) {
    Sentry.init({
        dsn: environment.sentry_dsn,
        environment: environment.sentry_env,
        ignoreErrors: ['Non-Error exception captured'],
    });
}

if (environment.production) {
    enableProdMode();
}

platformBrowserDynamic()
    .bootstrapModule(AppModule)
    .then(() => console.log('Bootstrap success'))
    .catch((err) => console.error(err));
