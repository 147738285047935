import { Component, OnInit, inject, ChangeDetectionStrategy, signal } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthApiService } from '@app/data/services/auth-api.service';
import { catchError, filter, finalize, switchMap, take, tap } from 'rxjs/operators';
import { of } from 'rxjs';
import { SharedModule } from '@app/shared/shared.module';
import { AppStateService, ValidationService } from '@app/core/services';
import { untilDestroyed } from '@app/core/helpers/until-destroyed';

@Component({
    selector: 'app-invite',
    templateUrl: './invite.component.html',
    styleUrls: ['../auth.component.scss', './invite.component.scss'],
    imports: [SharedModule],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InviteComponent implements OnInit {
    form: FormGroup;
    errorMsg = signal<string>('');
    pendingAcceptInvite = signal(false);
    showPasswordFields = signal(false);
    inviteDetails = signal<any>({});

    private formBuilder = inject(FormBuilder);
    private route = inject(ActivatedRoute);
    private router = inject(Router);
    private authApiService = inject(AuthApiService);
    private validationService = inject(ValidationService);
    private appStateService = inject(AppStateService);
    private untilDestroyed = untilDestroyed();

    ngOnInit() {
        this.initForm();
        this.route.queryParams.subscribe((params) => {
            this.inviteDetails.set({
                token: params.token,
                email: params.email,
                silo_id: params.silo_id,
                silo_name: params.silo_name,
                organisation_id: params.organisation_id,
                organisation_name: params.organisation_name,
                resetPassword: params['reset-password'] === 'true',
            });
            this.showPasswordFields.set(this.inviteDetails().resetPassword);
            this.validateToken();
        });
    }

    initForm() {
        this.form = this.formBuilder.group(
            {
                password: ['', this.showPasswordFields() ? [Validators.required, Validators.minLength(8)] : []],
                password_confirm: ['', this.showPasswordFields() ? [Validators.required] : []],
            },
            { validators: this.validationService.matchPasswordValidator },
        );
    }

    validateToken() {
        this.authApiService
            .validateInviteToken(
                this.inviteDetails().token,
                this.inviteDetails().email,
                this.inviteDetails().resetPassword,
                this.inviteDetails().silo_id,
                this.inviteDetails().organisation_id,
            )
            .pipe(
                catchError((err) => {
                    this.errorMsg.set('Invalid or expired invite token');
                    return of(null);
                }),
            )
            .subscribe();
    }

    acceptInvite() {
        if (this.form.invalid) return;

        this.pendingAcceptInvite.set(true);
        this.errorMsg.set('');
        this.appStateService.isAuthorized$
            .pipe(
                this.untilDestroyed(),
                tap((isAuthorized) => {
                    if (isAuthorized) {
                        this.appStateService.logoutUser();
                    }
                }),
                filter((isAuthorized) => !isAuthorized),
                take(1),
                switchMap(() =>
                    this.authApiService.acceptInvite(
                        this.inviteDetails().token,
                        this.inviteDetails().email,
                        this.inviteDetails().resetPassword,
                        this.passwordControl?.value,
                        this.confirmPasswordControl?.value,
                        this.inviteDetails().silo_id,
                        this.inviteDetails().organisation_id,
                    ),
                ),
                tap(() => {
                    this.router.navigate(['login'], {
                        queryParams: {
                            email: this.inviteDetails().email,
                            message: btoa('Invite accepted successfully. Please log in.'),
                        },
                    });
                }),
                catchError((err) => this.#onAcceptInviteError(err)),
                finalize(() => {
                    this.pendingAcceptInvite.set(false);
                }),
            )
            .subscribe();
    }

    clearError() {
        this.errorMsg.set('');
    }

    #onAcceptInviteError(err: any) {
        const errorMsg = this.validationService.handleServerErrors(err, this.form);
        if (errorMsg) {
            this.errorMsg.set(errorMsg);
        } else {
            this.errorMsg.set('An error occurred while accepting the invite');
        }
        return of(null);
    }

    get passwordControl() {
        return this.form.get('password');
    }
    get confirmPasswordControl() {
        return this.form.get('password_confirm');
    }
}
