import { Component, OnChanges, OnInit } from '@angular/core';
import { AuthApiService } from '@app/data/services/auth-api.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ValidationService } from '@app/core/services/validation.service';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { of } from 'rxjs';
import { catchError, finalize, mergeMap, tap } from 'rxjs/operators';

@Component({
    selector: 'app-change-password',
    templateUrl: './change-password.component.html',
    styleUrls: ['../auth.component.scss', 'change-password.component.scss'],
    standalone: false,
})
export class ChangePasswordComponent implements OnInit, OnChanges {
    localUser = {
        email: '',
        token: '',
    };
    errorMsg = '';
    showForm = false;
    passwordUpdateForm: FormGroup;
    pendingChangePasswordRequest = false;

    constructor(
        private formBuilder: FormBuilder,
        private authService: AuthApiService,
        private validationService: ValidationService,
        private route: ActivatedRoute,
        private router: Router,
    ) {}

    ngOnInit() {
        this.createPasswordUpdateForm();
        // Validate token with email
        this.route.queryParams
            .pipe(
                mergeMap((queryParams) =>
                    this.authService.validateToken(queryParams.token, queryParams.email).pipe(
                        tap(() => {
                            this.localUser.email = queryParams.email;
                            this.localUser.token = queryParams.token;
                            this.showForm = true;
                        }),
                        catchError((err) => {
                            this.errorMsg = err.error.message;
                            return of(err);
                        }),
                    ),
                ),
            )
            .subscribe();
    }

    ngOnChanges(changes) {
        if (
            changes.groups &&
            !changes.groups.firstChange &&
            changes.groups.currentValue.length !== changes.groups.previousValue.length
        ) {
            const control = new FormControl();
            (this.passwordUpdateForm.controls.groups as FormArray).push(control);
        }
    }

    createPasswordUpdateForm() {
        this.passwordUpdateForm = this.formBuilder.group(
            {
                newPassword: [
                    '',
                    [Validators.required, Validators.minLength(8), this.validationService.charTypeValidator],
                ],
                repeatPassword: ['', Validators.required],
            },
            { validators: this.validationService.matchPasswordValidator },
        );
    }

    // Shortcut getters
    get newPasswordControl() {
        return this.passwordUpdateForm.get('newPassword');
    }

    get repeatPasswordControl() {
        return this.passwordUpdateForm.get('repeatPassword');
    }

    get newPasswordInvalid() {
        const newPassword = this.passwordUpdateForm.get('newPassword');
        return newPassword.errors?.required || newPassword.errors?.minlength || newPassword.errors?.charTypes;
    }

    requestPasswordUpdate() {
        this.pendingChangePasswordRequest = true;
        this.authService
            .requestPasswordChange(this.localUser.token, this.localUser.email, this.newPasswordControl.value)
            .pipe(
                tap(() => {
                    this.passwordWasUpdated();
                }),
                catchError((err) => {
                    this.errorMsg = err.error.email;
                    return of(err);
                }),
                finalize(() => {
                    this.pendingChangePasswordRequest = false;
                }),
            )
            .subscribe();
    }

    passwordWasUpdated() {
        return this.router.navigate(['login'], {
            queryParams: {
                email: this.localUser.email,
                message: btoa('Password has been updated successfully'),
            },
            queryParamsHandling: 'merge',
        });
    }

    goBackToLogin() {
        this.router.navigate(['login']);
    }

    clearError() {
        this.errorMsg = '';
    }

    get submitButtonDisabled() {
        return (
            this.newPasswordInvalid || this.repeatPasswordControl.errors?.mismatch || this.pendingChangePasswordRequest
        );
    }
}
