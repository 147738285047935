<form class="login-form" novalidate [formGroup]="form">
    <div class="failed-msg" data-testid="error-message" [ngClass]="{ 'alert alert-danger': errorMsg }">
        {{ errorMsg }}
    </div>

    <mat-form-field hideRequiredMarker>
        <mat-label>Email:</mat-label>
        <mat-icon matPrefix><i class="fal fa-user"></i></mat-icon>
        <input
            class="form-control validate"
            id="login_email"
            type="email"
            name="email"
            formControlName="email"
            required
            (focus)="clearError()"
            matInput
        />
        @if (emailControl.value && !emailControl.valid) {
            <mat-error data-testid="invalid-email-error">Invalid Email</mat-error>
        }
        @if (!emailControl.value || emailControl.untouched) {
            <mat-error data-testid="empty-email-error">Email is required</mat-error>
        }
    </mat-form-field>

    <button
        class="btn submit_btn auth-page-btn reset-password-btn"
        data-testid="reset-password-button"
        mat-button
        (click)="requestPasswordReset()"
        [disabled]="!emailControl.valid || pendingResetPasswordRequest"
    >
        <span *msLoadingSpinner="pendingResetPasswordRequest; diameter: 20">Reset password</span>
    </button>
    <div class="small-button-wrapper--center additional-options-wrapper" id="back_to_login">
        <a (click)="goBackToLogin()" class="small-button-link">Go back</a>
    </div>
</form>
