import { ActionReducerMap, MetaReducer } from '@ngrx/store';

import { environment } from '../../../../environments/environment';
import { AppState } from './state';
import * as fromChannelsList from '../channels-list';
import * as fromFeatureFlags from '../feature-flags';
import * as fromThemingConfig from '../theming';
import * as fromUser from '../user';
import { routerReducer } from '@ngrx/router-store';

export const reducers: ActionReducerMap<AppState> = {
    user: fromUser.userReducer,
    featureFlags: fromFeatureFlags.featureFlagsReducer,
    themingConfig: fromThemingConfig.themingReducer,
    channelsList: fromChannelsList.channelsListReducer,
    router: routerReducer,
};

export const metaReducers: MetaReducer<AppState>[] = !environment.production ? [] : [];
