<form class="login-form" novalidate [formGroup]="form">
    @if (errorMsg()) {
        <div class="failed-msg alert alert-danger" data-testid="error-message">
            <ul>
                <li>{{ errorMsg() }}</li>
            </ul>
        </div>
    }

    <h2>
        Accept invitation to <b>{{ inviteDetails().silo_name }}</b>
        <span *ngIf="inviteDetails().organisation_name">
            / <b>{{ inviteDetails().organisation_name }}</b></span
        >
    </h2>
    <p>Email: {{ inviteDetails().email }}</p>

    @if (showPasswordFields()) {
        <mat-form-field hideRequiredMarker>
            <mat-label>Password:</mat-label>
            <mat-icon matPrefix><i class="fal fa-lock"></i></mat-icon>
            <input
                class="form-control validate"
                id="invite_password"
                type="password"
                name="password"
                formControlName="password"
                required
                (focus)="clearError()"
                matInput
            />
            @if (!passwordControl.value || passwordControl.untouched) {
                <mat-error data-testid="empty-password-error">Password is required</mat-error>
            }
            @if (passwordControl.errors?.serverError) {
                <mat-error data-testid="server-error">{{ passwordControl.errors.serverError }}</mat-error>
            }
        </mat-form-field>

        <mat-form-field hideRequiredMarker>
            <mat-label>Confirm Password:</mat-label>
            <mat-icon matPrefix><i class="fal fa-lock"></i></mat-icon>
            <input
                class="form-control validate"
                id="invite_confirm_password"
                type="password"
                name="confirmPassword"
                formControlName="password_confirm"
                required
                (focus)="clearError()"
                matInput
            />
            @if (confirmPasswordControl.value !== passwordControl.value) {
                <mat-error data-testid="password-mismatch-error">Passwords do not match</mat-error>
            }
            @if (confirmPasswordControl.errors?.serverError) {
                <mat-error data-testid="server-error">{{ confirmPasswordControl.errors.serverError }}</mat-error>
            }
        </mat-form-field>
    }

    <button
        class="btn submit_btn auth-page-btn accept-invite-btn"
        data-testid="accept-invite-button"
        mat-button
        (click)="acceptInvite()"
        [disabled]="!form.valid || pendingAcceptInvite()"
    >
        <span *msLoadingSpinner="pendingAcceptInvite(); diameter: 20">Accept invite</span>
    </button>
</form>
