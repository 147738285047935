import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';

import { reducers, metaReducers } from './reducer';
import { RootEffects } from './effects';

@NgModule({
    imports: [
        StoreModule.forRoot(reducers, {
            metaReducers,
        }),
        EffectsModule.forRoot(RootEffects),
    ],
    providers: RootEffects,
})
export class RootStoreModule {}
