<form class="login-form" novalidate [formGroup]="form">
    <div
        id="login-error-message"
        class="info-msg"
        [ngClass]="{ 'alert alert-danger': (errorMsg | async) }"
        data-testid="alert-error"
    >
        {{ errorMsg | async }}
    </div>
    <div class="info-msg" [ngClass]="{ 'alert alert-success': infoMsg }" data-testid="alert-success">{{ infoMsg }}</div>
    @if (isSessionExpired) {
        <div class="message">Session expired</div>
    }
    <mat-form-field hideRequiredMarker>
        <mat-label>Email:</mat-label>
        <mat-icon matIconPrefix><i class="fal fa-user"></i></mat-icon>
        <input
            class="form-control validate"
            id="login_email"
            formControlName="email"
            type="email"
            name="email"
            matInput
        />
        @if (emailControl.value && !emailControl.valid && emailControl.errors?.serverError) {
            <mat-error>{{ emailControl.errors.serverError }}</mat-error>
        } @else if (emailControl.value && !emailControl.valid) {
            <mat-error>Invalid Email</mat-error>
        }
        @if (!emailControl.value || emailControl.untouched) {
            <mat-error>Email is required</mat-error>
        }
    </mat-form-field>
    <mat-form-field hideRequiredMarker>
        <mat-label>Password:</mat-label>
        <mat-icon matPrefix><i class="fal fa-lock"></i></mat-icon>
        <input
            class="form-control validate"
            id="login_password"
            formControlName="password"
            type="password"
            required
            matInput
        />
        @if (!passwordControl.value || passwordControl.untouched) {
            <mat-error>Password is required</mat-error>
        }
        @if (passwordControl.errors?.serverError) {
            <mat-error>{{ passwordControl.errors.serverError }}</mat-error>
        }
    </mat-form-field>
    <div class="form-group">
        <div class="additional-options-wrapper">
            <div class="small-button-wrapper--right" id="forgotten_password">
                <a (click)="forgottenPassword()">Forgot password?</a>
            </div>
        </div>
    </div>
    <div class="clear"></div>
    <button
        class="btn submit_btn auth-page-btn"
        mat-button
        (click)="login()"
        [disabled]="submitButtonDisabled"
        data-testid="login"
    >
        <span *msLoadingSpinner="pendingLoginRequest; diameter: 20">Log in</span>
    </button>
    <div class="clear"></div>
</form>
