import { Component } from '@angular/core';

import { environment } from '@env/environment';
import { Observable } from 'rxjs';
import { AppStateService } from './core/services/app-state.service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    standalone: false,
})
export class AppComponent {
    themeLoaded$: Observable<boolean>;

    constructor(private appStateService: AppStateService) {
        this.loadGoogleMapsScript();
        this.appStateService.getThemingConfig();
        this.themeLoaded$ = this.appStateService.themeLoaded$;
    }

    loadGoogleMapsScript() {
        const body = <HTMLDivElement>document.body;
        const script = document.createElement('script');
        script.innerHTML = '';

        script.src = `https://maps.googleapis.com/maps/api/js?key=${environment.maps_api_key}&libraries=places&callback=initMap`;
        script.async = true;
        script.defer = true;
        window['initMap'] = () => {};
        body.appendChild(script);
    }

    loadWaffleJsScript() {
        const body = <HTMLDivElement>document.body;
        const script = document.createElement('script');
        script.innerHTML = '';

        script.src = `${environment.saga_api_url}wafflejs`;
        script.defer = true;
        body.appendChild(script);
    }
}
