import { Component, OnInit } from '@angular/core';
import { tap } from 'rxjs/operators';

import { AppStateService } from '@app/core/services/app-state.service';
import { untilDestroyed } from '@app/core/helpers/until-destroyed';

@Component({
    selector: 'app-auth',
    templateUrl: './auth.component.html',
    styleUrls: ['./auth.component.scss', './auth-forms.scss'],
    standalone: false,
})
export class AuthComponent implements OnInit {
    titleLogoSrc: string;
    title: string;
    private untilDestroy = untilDestroyed();

    constructor(private appStateService: AppStateService) {}

    ngOnInit() {
        this.appStateService.themingConfig$
            .pipe(
                this.untilDestroy(),
                tap((themingConfig) => {
                    this.title = themingConfig.loginScreenTitle;
                    this.titleLogoSrc = themingConfig.loginScreenImage ? themingConfig.loginScreenImage : null;
                }),
            )
            .subscribe();
    }
}
