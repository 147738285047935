import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';

import { CommonModule, DatePipe } from '@angular/common';
import { StoreRouterConnectingModule } from '@ngrx/router-store';
import { RootStoreModule } from '@app/core/state/root-store/root-store.module';
import { MATOMO_ROUTER_CONFIGURATION, MatomoModule, MatomoRouterModule } from 'ngx-matomo-client';
import { matomoRouterConfig, matomoTrackingConfig, pendoApiKey } from './constants/tracking';
import { NgxPendoModule } from 'ngx-pendo';

@NgModule({
    imports: [
        CommonModule,
        RootStoreModule,
        StoreRouterConnectingModule.forRoot(),
        MatomoModule.forRoot(matomoTrackingConfig),
        MatomoRouterModule.forRoot(),
        NgxPendoModule.forRoot({
            pendoApiKey,
        }),
    ],
    providers: [{ provide: MATOMO_ROUTER_CONFIGURATION, useValue: matomoRouterConfig }, DatePipe],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class CoreModule {}
