import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { AuthComponent } from './auth.component';
import { LoginGuard } from '@app/core/guards/auth.guard';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { SelectSiloComponent } from './select-silo/select-silo.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { InviteComponent } from './invite/invite.component';
import { OtpCheckComponent } from './otp-check/otp-check.component';

const routes: Routes = [
    {
        path: '',
        component: AuthComponent,
        canActivate: [LoginGuard],
        children: [
            {
                path: 'login',
                children: [
                    {
                        path: '',
                        component: LoginComponent,
                        canActivate: [LoginGuard],
                    },
                    {
                        path: ':flag',
                        component: LoginComponent,
                        canActivate: [LoginGuard],
                    },
                ],
            },
            { path: 'otp-check', component: OtpCheckComponent },
            { path: 'select-silo', component: SelectSiloComponent, canActivate: [LoginGuard] },
            { path: 'change-password', component: ChangePasswordComponent, canActivate: [LoginGuard] },
            { path: 'reset-password', component: ResetPasswordComponent },
            { path: 'accept-invite', component: InviteComponent },
        ],
    },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
})
export class AuthRoutingModule {}
